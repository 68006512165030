/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/

// Array of sections

export default [
  {
    title: 'Аналитика',
    icon: 'BarChart2Icon',
    route: 'analytics',
    permission: 'analyticAccess',
  },
  {
    title: 'Клиенты',
    icon: 'UsersIcon',
    route: 'clients',
    permission: 'clientsAccess',
  },
  {
    title: 'Получатели',
    icon: 'UserCheckIcon',
    route: 'receivers',
    permission: 'recipientBaseAccess',
  },
  {
    title: 'База заказов',
    icon: 'ShoppingCartIcon',
    route: 'order-base',
    permission: 'ordersAccess',
  },
  {
    title: 'Календарь заказов',
    icon: 'CalendarIcon',
    route: 'order-calendar',
  },
  {
    title: 'Календарь расходов',
    icon: 'BookOpenIcon',
    route: 'calendar-of-expenses',
    permission: 'costsCalendarAccess',
  },
  {
    title: 'Карта заказов',
    icon: 'MapIcon',
    route: 'order-map',
    permission: 'ordersMapAccess',
  },
  {
    title: 'Маршруты',
    icon: 'MapPinIcon',
    route: 'routes',
    permission: 'routsAccess',
  },
  {
    title: 'Сотрудники',
    icon: 'UserIcon',
    permission: 'staffAccess',
    // tag: '1',
    tagVariant: 'light-primary',
    children: [
      {
        title: 'База сотрудников',
        route: 'base-of-staff',
      },
      {
        title: 'Роли',
        route: 'roles',
      },
    ],
  },
  {
    title: 'Настройки',
    icon: 'SettingsIcon',
    permission: 'settingAccess',
    // tag: '2',
    tagVariant: 'light-primary',
    children: [
      {
        title: 'Источники заказов',
        route: 'sources-of-orders',
      },
      {
        title: 'Курьерские службы',
        route: 'courier-services',
      },
      {
        title: 'Список городов',
        route: 'list-of-cities',
      },
    ],
  },
]
